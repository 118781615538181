import $ from 'jquery';
import { Fancybox } from "@fancyapps/ui";


import "@fancyapps/ui/dist/fancybox/fancybox.css";
import './../../scss/blocks/block-ptgallery.scss';

$(document).ready(function() {
    Fancybox.bind("[data-fancybox]", {
    });
});